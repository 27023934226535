<template>
  <v-container fluid>
    <v-col cols="3">
      <v-btn
        block
        height="42px"
        color="#1db954"
        class="download-btn"
        @click="showModal"
        style="color: black"
      >
        <v-icon>mdi mdi-plus-thick</v-icon>
        Neu
      </v-btn>
    </v-col>

    <v-card>
      <v-data-table
        :headers="headers"
        :items="baumarts"
        :loading="isLoading"
        :search="performSearch"
        class="elevation-1"
        :footer-props="{
          'items-per-page-text': 'Zeilen pro Seite',
        }"
      >
        <template v-slot:item.actions="{ item }">
          <div class="button-container">
            <v-icon
              small
              class="edit green--text icon-style mr-2"
              @click="editItem(item)"
              >mdi-pencil</v-icon
            >
            <v-icon
              small
              class="delete green--text icon-style"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <baumart-create
      :item-list="baumarts"
      :visible="showModalDialog"
      @close="closeModalDialog"
      v-on:updated-table="updatedList"
      @item-created="handleItemCreated"
    ></baumart-create>
    <baumart-update
      :baumart="selectedItem"
      :item-list="baumarts"
      :visible="showUpdateDialog"
      v-on:updated-table="updatedList"
      @close="closeUpdateDialog"
    ></baumart-update>
    <baumart-delete
      :visible="showDeleteDialog"
      :baumart="selectedItem"
      v-on:updated-table="updatedList"
      @close="closeDeleteDialog"
    ></baumart-delete>
  </v-container>
</template>

<script>
import axios from "axios";
import BaumartCreate from "./BaumartCreate";
import BaumartUpdate from "./BaumartUpdate";
import BaumartDelete from "./BaumartDelete";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    BaumartCreate,
    BaumartUpdate,
    BaumartDelete,
  },
  data() {
    return {
      headers: [
        { text: "Abkürzung", value: "abbreviation" },
        { text: "Latein", value: "latinName" },
        { text: "Deutsch", value: "germanName" },
        { text: "", value: "actions", sortable: false },
      ],
      baumarts: [],
      baumartUpdateKey: null,
      isLoading: false,
      showModalDialog: false,
      showUpdateDialog: false,
      showDeleteDialog: false,
      selectedItem: {},
      localSearch: null,
    };
  },
  computed: {
    ...mapGetters(["getBaumartlisteResults", "getSearchquery"]),

    performSearch() {
      return this.getSearchquery;
    },
  },
  mounted() {
    this.localSearch = this.getSearchquery;
    this.test();
  },
  created() {
    this.fetchData();
  },
  methods: {
    updatedList(boolean) {
      if (boolean) {
        this.fetchData();
      }
    },

    handleItemCreated() {
      this.fetchData(); // Fetch data again to update the list immediately
    },

    fetchData() {
      this.isLoading = true;
      axios
        .get("https://tilia-ch.rrota.org/api/TreeType/GetAll")
        .then((response) => {
          this.$store.commit("setBaumartlisteResults", response.data);
          this.baumarts = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    handleSearch(query) {
      this.getSearchquery = query;
    },

    showModal() {
      this.showModalDialog = true;
    },
    closeModalDialog() {
      this.showModalDialog = false;
    },
    editItem(item) {
      this.selectedItem = item;
      this.showUpdateDialog = true;
    },
    deleteItem(item) {
      this.selectedItem = item;
      this.showDeleteDialog = true;
    },
    closeUpdateDialog() {
      this.showUpdateDialog = false;
      this.selectedItem = {};
    },
    closeDeleteDialog() {
      this.showDeleteDialog = false;
      this.selectedItem = {};
    },
  },
};
</script>
<style>
.add-button {
  outline: none !important;
  margin-bottom: 10px;
  width: 220px;
}
.icon-style {
  border: 1px solid #494949;
  padding: 3px;
  border-radius: 5px;
  width: 42px;
  height: 42px;
  margin-left: -60px !important;
}
.button-container {
  display: flex;
  align-items: center;
}
.button-container .delete {
  position: absolute;
  right: 15px;
}
.button-container .edit {
  position: absolute;
  right: 57px;
}

@media screen and (min-width: 768px) {
  /* adjust the styles for iPad Air */
  .search-box {
    width: 200px;
    margin-left: 20px;
  }
}
</style>
